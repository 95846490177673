import * as React from "react";
import { Script } from "gatsby";

export const GoogleAnalytics = () => {
  if (process.env.NODE_ENV !== "production") {
    return null;
  }

  return (
    <>
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-V41V1PV67H"></Script>
      <Script>
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-V41V1PV67H');
      `}
      </Script>
    </>
  );
};
