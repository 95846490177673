import "normalize.css";
import "@fontsource/manrope/latin-400.css";
import "@fontsource/manrope/latin-500.css";
import "@fontsource/manrope/latin-600.css";
import "@fontsource/manrope/latin-700.css";

import "./src/styles/theme.css";

import * as React from "react";
import { GoogleAnalytics } from "./src/components/google-analytics";
// import { Intercom } from "./src/components/intercom";

export const wrapRootElement = ({ element }) => (
  <>
    {/* <Intercom /> */}
    {element}
    <GoogleAnalytics />
  </>
);
